import Jogador from "./jogador"
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import "./partida.css"

function Partida({dados, ids, substituirID}) {      

    function converterTimestampParaTempo(timestamp) {
        let hora = Math.floor(timestamp / 3600)
        let minuto = Math.floor(timestamp / 60 % 60)
        let segundo = timestamp % 60
        
        if (minuto < 10) {
            minuto = '0'+ minuto
        }
        if (segundo < 10) {
            segundo = '0'+ segundo
        }
        return hora + ':' + minuto + ':' + segundo
    }
        
    return (
        <div className="row mt-5 cor0" >
            <div className="col-12">
                <div className="row pt-3">
                    <div className="col-3"><p>Partida: {dados.match_id}</p></div>
                    <div className="col-3"><p>Vencedor: {dados.radiant_win ? "Sentinela" : "Temidos"}</p></div>
                    <div className="col-3"><p>Data: {format(dados.start_time * 1000, 'dd/MM/yyyy HH:mm:ss', { locale: ptBR })}</p></div>
                    <div className="col-3"><p>Duração: {converterTimestampParaTempo(dados.duration)}</p></div>
                </div>                
            </div>
            <div className="col-12 mb-3">
                <div className="row">
                    <div className="col-6">
                        <h5>Radiante</h5>
                        <br />                        
                        {
                            dados.players.map((item, index) => {
                                // Verificar se item.player_slot é menor que 5
                                if (item.player_slot < 5) {
                                    return <Jogador key={index} player={item} ids={ids} substituirID={substituirID} />;
                                } else {
                                    return null; // Renderizar null se não atender à condição
                                }
                            })
                        }                        
                    </div>
                    <div className="col-6">
                        <h5>Temidos</h5>
                        <br />                        
                        {
                            dados.players.map((item, index) => {
                                // Verificar se item.player_slot é menor que 5
                                if (item.player_slot > 5) {
                                    return <Jogador key={index} player={item} ids={ids} substituirID={substituirID} />;
                                } else {
                                    return null; // Renderizar null se não atender à condição
                                }
                            })
                        }                        
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Partida