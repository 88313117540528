import React from "react";
import Input from "./input";
import Totais from "./totais";
import Partida from "./partida";
import Carregando from "./carregando";


function Buscador({buscarTodasAsPartidas, ids, setIds, detalhadas, partidasContra, mostrar, estadoMostrar, useApresentar, nomesUsuarios, zeroPartidas, substituirID, exemplo}) {
  
  
  return (
    <form action="">
      <div className="container">
        <div className="row justify-content-center mt-3">
          <Input ids={ids} setIds={setIds} numero={1} />
          <button className="col-2 btn btn-success" type="button" onClick={exemplo}>
              Exemplo
          </button>
          <Input ids={ids} setIds={setIds} numero={2} />          
          <button className="col-2 btn btn-success" type="button" onClick={buscarTodasAsPartidas}>
              Comparar
          </button> 
        </div>
        
        <div className="row justify-content-center mt-2">
          { useApresentar == '' && nomesUsuarios[0] != '' ? <Carregando nomesUsuarios={nomesUsuarios} ids={ids} zeroPartidas={zeroPartidas} /> : ''}
          { detalhadas.length ? <Totais 
          detalhadas={detalhadas}
          partidasContra={partidasContra}
          mostrar={mostrar}
          estadoMostrar={estadoMostrar}
          ></Totais> : ""}
          
          {
            useApresentar.map((item, index) => (
              <Partida key={index} dados={item} ids={ids} substituirID={substituirID} />
            ))            
          }
        </div>
      </div>
    </form>
  );
}

export default Buscador;
