function Home() {
    return (
        <div className="pt-4">
            <h1>Recado do Criador</h1>
            <br/>
            <h4>A página busca partidas em comum entre dois jogadores</h4>
            <br />
            <br />
            <h5>Ambos os jogadores precisam estar com o dados de partida compartilhados.</h5>
            <h5>Reativar o compartilhamento não libera a pesquisa das partidas do período bloqueado.</h5>
            <br /><br />
        </div>
    )
}

export default Home