import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Container from './componente/container'
import Header from "./componente/header";
import Home from "./componente/home";
import './App.css';
import Pesquisa from "./componente/pesquisa";

function App() {
  return (
    <div className="App fundo_geral">
      <Router>
        <Header />   
        <Container >
          <Routes>
            <Route exact path="/" element= {< Home />}/>
            <Route path="/pesquisa" element= {< Pesquisa />}/>
          </Routes>
        </Container>        
      </Router>
    </div>
  );
}

export default App;
