function Input({ numero, setIds, ids }) {
    
    const handleChange = (event) => {
        const novoArray = [...ids]
        novoArray[numero - 1] = event.target.value;
        setIds(novoArray)
    }
  
  return (
    <div className="col-3">
      <div className="form-group">
        <label className="mx-1">Jogador { numero } : </label>
        <input className="mx-1" type="text" value={ids[numero - 1]} placeholder="Digite o ID" onChange={handleChange}/>
      </div>
    </div>
  );
}

export default Input;
