import axios from "axios";
import Buscador from "./buscador";
import { useState } from "react";


function Pesquisa() {

  // Variáveis de controle ------------------------------------------------------------------------------------------------
  const [idsJogadores, setIdsJogadores] = useState(["", ""])
  const [nomesUsuarios, setNomesUsuarios] = useState([])
  let partidas = [[],[]]
  let partidasEmComum = []
  const [zeroPartidas, setZeroPartidas] = useState(false)
  let partidasDetalhadas = []
  const [detalhadas, setDetalhadas] = useState([])  
  const [usePartidasContra, setPartidasContra] = useState([])
  const [estadoMostrar, setEstadoMostrar] = useState(true);
  const [useApresentar, setApresentar] = useState([])  
  
  // Conferir se jogadores existem ---------------------------------------------------------------------------------------
  const buscarDadosJogador = async (id, index) => {
    
    try {      
      const response = await axios.get(`https://api.opendota.com/api/players/${id}`);
      const nomeJogador = response.data.profile.personaname;
      setNomesUsuarios(prevNomesUsuarios => [...prevNomesUsuarios, nomeJogador])  
            
    } catch (e) {
      setNomesUsuarios(prevNomesUsuarios => [...prevNomesUsuarios, 'Histórico Bloqueado'])    
    }
  };  

  const compararJogadores = async () => {    
    await buscarDadosJogador(idsJogadores[0], 0);
    await buscarDadosJogador(idsJogadores[1], 1);    
  };

  // Retornar Array de id das Partidas -----------------------------------------------------------------------------------------

  const buscarListaDePartidas = async (param) => {
    try { 
      const response = await axios.get(`https://api.opendota.com/api/players/${param}/matches`);      
      return response.data;
    } catch (error) {
      console.log('Erro ao buscar lista de partidas:', error);
      throw error; // Rethrow para propagar o erro para quem chamou esta função
    }
  }
  
  // Função principal para buscar partidas em comum
  const buscarPartidasEmComum = async () => {
    try {
      await compararJogadores();
  
      // Usando Promise.all para esperar todas as chamadas de buscarListaDePartidas
      const resultados = await Promise.all(idsJogadores.map(async (id, index) => {        
        return buscarListaDePartidas(id);
      }));
  
      // Agora, resultados é um array de arrays de partidas. Podemos concatená-lo para ter um único array
      partidas = resultados.flat();
    } catch (error) {
      console.log('Erro ao buscar partidas em comum:', error);
    }
  }

  const encontrarRepetidos = async () => {
    try {
      await buscarPartidasEmComum();
  
      // Extrair apenas os match_id para simplificar a comparação
      const matchIds = partidas.map(partida => partida.match_id);
  
      // Array para armazenar os match_id repetidos
      const repetidos = matchIds.filter((matchId, index) => {
        // Verifica se o índice do elemento atual é diferente do índice da primeira ocorrência
        return matchIds.indexOf(matchId) !== index;
      });      
      partidasEmComum = repetidos;
      return repetidos;
    } catch (error) {
      console.log('Erro ao encontrar valores repetidos:', error);
      throw error;
    }
  };

  // Retornar o Array com cada partida em si ----------------------------------------------------------------------------------

  const buscarTodasAsPartidas = async () => {
    await encontrarRepetidos();
    let agrupandoPartidas = [];

    // Função para aguardar um intervalo de tempo
    const esperar = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    // Loop assíncrono para fazer as solicitações com um intervalo de tempo
    for (let i = 0; i < partidasEmComum.length; i++) {
        // Aguardar o intervalo de tempo entre as solicitações
        if (i > 0) {
            await esperar(800); // Aguarda 0,8 segundos entre as solicitações (1000 milissegundos = 1 segundo)
        }

        try {
            // Fazer a solicitação Axios
            const response = await axios.get(`https://api.opendota.com/api/matches/${partidasEmComum[i]}`);
            // Adicionar os dados da resposta ao array agrupandoPartidas
            agrupandoPartidas.push(response.data);
            setDetalhadas(prevDetalhadas => [...prevDetalhadas, response.data]);
            setApresentar(prevApresentar => [...prevApresentar, response.data])
            
        } catch (error) {
            console.error('Erro ao buscar dados da partida:', error);
        }
    }

    if ( detalhadas.length == 0) {
      setZeroPartidas(true)
    }
    // Atualizar o estado partidasDetalhadas após todas as solicitações serem concluídas
    partidasDetalhadas = agrupandoPartidas
};
  
  //Descobrir Partidas Contra ----------------------------------------------------------------------------------------
  
  const descobrirPartidasContra = async () => {

    // Resetar todos os valores
    setNomesUsuarios([])
    partidas = [[],[]]
    partidasEmComum = []
    partidasDetalhadas = []
    setZeroPartidas(false)
    setDetalhadas([])  
    setPartidasContra([])
    setEstadoMostrar(true)
    setApresentar([])

    await buscarTodasAsPartidas()
    let contra = []
    for ( let i = 0 ; i < partidasDetalhadas.length; i++) {
      let jogador1 = ''
      let jogador2 = ''
      for (let j = 0; j < partidasDetalhadas[i].players.length; j++ ) { 
               
        if (partidasDetalhadas[i].players[j].account_id == idsJogadores[0] ) {
          jogador1 = partidasDetalhadas[i].players[j].team_number          
        }
        if (partidasDetalhadas[i].players[j].account_id == idsJogadores[1] ) {
          jogador2 = partidasDetalhadas[i].players[j].team_number
        }
      }      
      if ( jogador1 !== jogador2)
      contra.push(partidasDetalhadas[i])           
    }

    // recuperar essas partidas contra --------------------------------------------------------------------------------
    
    setPartidasContra(contra)    
  }

  // Alternar partidas contra ou todas as partidas

  function mostrar(event) {
    event.preventDefault();
    if (estadoMostrar == true) {
      setApresentar(usePartidasContra);      
      setEstadoMostrar(prevEstado => !prevEstado);
    } else if( estadoMostrar == false) {
      setApresentar(detalhadas)      
      setEstadoMostrar(prevEstado => !prevEstado);
    }
  }

  function substituirID(numero, valor) {
    // Cria uma cópia do array de idsJogadores
    const novosIds = [...idsJogadores];
    
    // Atualiza o valor do elemento no índice especificado
    novosIds[numero] = valor;

    // Atualiza o estado com o novo array de idsJogadores
    setIdsJogadores(novosIds);
  }

  function exemplo() {
    const arrayExemplo = ['131018831','137246221']
    setIdsJogadores(arrayExemplo)
  }

  
  return (
    <div className="pt-4">
      <Buscador 
      buscarTodasAsPartidas={descobrirPartidasContra} 
      ids={idsJogadores}
      nomesUsuarios={nomesUsuarios}
      setIds={setIdsJogadores}
      detalhadas = {detalhadas}
      zeroPartidas={zeroPartidas}
      useApresentar={useApresentar}
      partidasContra={usePartidasContra}
      mostrar={mostrar}
      estadoMostrar={estadoMostrar}
      substituirID={substituirID}
      exemplo={exemplo}
       />    
    </div>
    
  );
}

export default Pesquisa;
