function Totais({detalhadas, partidasContra, mostrar, estadoMostrar}) {
    
    return (
        <div className="row my-3">
            <div className="col-3">
                Total de Partidas: {detalhadas.length}
            </div>
            <div className="col-3">
                
            </div>
            <div className="col-3">
                Partidas Contra: {partidasContra.length}
            </div>
            { partidasContra.length > 0 ? 
            <div className="col-3"><button onClick={mostrar} className=" btn btn-warning">{estadoMostrar ? "Mostrar" : "Voltar"}</button></div> : ''}
        </div>
    )
}

export default Totais