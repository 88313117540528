function Carregando({nomesUsuarios, ids, zeroPartidas}) {

    let renderizar

    if ( nomesUsuarios[0] != null && nomesUsuarios[1] != null ) {
        if(nomesUsuarios[0] != 'Histórico Bloqueado' && nomesUsuarios[1] != 'Histórico Bloqueado' ) {
            renderizar = `Jogadores ${nomesUsuarios[0]} e ${nomesUsuarios[1]} encontrados`
        }
        else {
            if (nomesUsuarios[0] == 'Histórico Bloqueado') {
                renderizar = `Jogador com ID: ${ids[0]} bloqueou histórico ou ID não existe`
            } else if (nomesUsuarios[1] == 'Histórico Bloqueado') {
                renderizar = `Jogador com ID: ${ids[1]} bloqueou histórico ou ID não existe`
            }
        }        
    }
        

    return (
        <div>
            { renderizar }
            <br />
            <br />
            { zeroPartidas && nomesUsuarios[0] != 'Histórico Bloqueado' && nomesUsuarios[1] != 'Histórico Bloqueado' ? 'Mas nenhuma partida em comum foi encontrada' : ''}
        </div>
    )
}

export default Carregando