import { Link } from 'react-router-dom'
import './header.css'

function Header() {
    return(
        <div className="geral">
            <div className="container">
                <div className="row">
                    <div className="col-3">
                    <Link to={'/'}><p className="logo">Egodota</p></Link>                        
                    </div>
                    <div className="col-9 text-center align-self-center definicao justify-content-center">
                        <Link className='mx-3' to={'/'}>Home</Link>
                        <Link className='mx-3' to={'/pesquisa'}>Pesquisa</Link>
                        <Link className='mx-3' to={'/'}>Sobre</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header