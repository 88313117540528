import lista from '../arquivos/herois/listaherois.json'
import "./jogador.css"

function Jogador({player, ids, substituirID}) {    

    let cor = ''

    if ( player.account_id == ids[0]) {
        cor = 'row jogador1'
    } else if ( player.account_id == ids[1] ) {
        cor = 'row jogador2'
    } else {
        cor = 'row'
    }

    

    let heroi = lista.find(item => item.id == player.hero_id)
    let imagem = require(`../arquivos/herois/${heroi.name}_sb.png`);
    let renderizar1 = ''
    let renderizar2 = ''
    

    if (player) {
        renderizar1 = <img src={imagem} alt="" />
        renderizar2 = player.personaname ? player.personaname : "Dados Não Compartilhados";
    }

    
 return(    
    <div>
        <div className={cor}>
            <div className="col-2 larg0 pad0">{ renderizar1 }</div>
            { renderizar2 != "Dados Não Compartilhados" ?
                <div className="col-10">   
                    <div className='row'>
                        <div className='col-2 align-middle'>{player.kills + '/' + player.deaths + '/' + player.assists + '  '}</div>
                        <div className='col-1'></div>
                        <div className="col-7 align-middle">{renderizar2}</div>
                        <div className="col-2 pad0">
                            <button type='button' className='atalho atalho1' onClick={() => substituirID(0, player.account_id)}>1</button> 
                            <button type='button' className='atalho atalho2' onClick={() => substituirID(1, player.account_id)}>2</button>
                        </div>
                    </div>
                </div> 
                 : 
                 <div className="col-10">   
                    <div className='row'>
                        <div className='col-2'>{player.kills + '/' + player.deaths + '/' + player.assists + '  '}</div>
                        <div className="col-9">{renderizar2}</div>
                    </div>
                </div>
            } 
        </div>
         
    </div>
 )
}

export default Jogador